// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import { setAuthToken, removeToken } from './store/slices/app/controlers/auth'

import Login from './app/pages/Auth/Auth'
import Home from './app/pages/Home'
import Updater from './app/pages/Updater'
import { AxiosInterceptor } from './services/app/axios-interceptor.service'
import SocketConnector from './services/app/socket-connector.service'
import ProtectedRoute from './routes/ProtectedRoute'

import Cookies from 'js-cookie'
import './index.scss'
import './index.layout.scss'
import './index.mobile.scss'

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [firstUpdate, setFirstUpdate] = useState(false)
  const reduxToken = useSelector(state => state.authReducer.authData.token)

  useEffect(() => {
    const TOKEN = Cookies.get('accessToken')

    TOKEN && dispatch(setAuthToken(TOKEN))
    !!!TOKEN && dispatch(removeToken())

    !!!false && setFirstUpdate(true)
    !!false && navigate('/metriva/protocol')
  }, [])

  return (
    <AxiosInterceptor>
      <SocketConnector />
      <div className='App'>
        {firstUpdate && <Updater />}
        <Routes>
          <Route
            path='/'
            element={
              !!!reduxToken ? <Login /> : <Navigate replace={true} to={'/metriva/protocol'} />
            }
          />
          <Route
            path='/metriva/*'
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </AxiosInterceptor>
  )
}

export default App
